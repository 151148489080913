<template>
  <div class="cut-version-application">
    <Content>
        <AskUserCode/>
    </Content>
  </div>
</template>

<script>
import AskUserCode from '@viewsCutVersion/AskUserCode.vue';

export default {
  name: 'Main.vue',
  components: {
    AskUserCode,
  },
};
</script>

<style lang="scss" scoped>
.cut-version-application {
  height: 100%;
}
.container {
  height: 100%;
}
</style>
